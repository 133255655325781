<template>
  <div class="navbar bg">
    <div v-for="i in 15" :key="i" :class="`dotWrapper dotWrapper-${i}`">
      <div :class="`dot dot-${i}`"></div>
    </div>
    <div class="arrowArea">
      <img
        class="arrowToRight"
        src="@/assets/icons/Arrows/PNG/AOG_arrow_right_wh.webp"
        alt="arrow-to-right"
      />
      <img
        class="arrowToLeft"
        src="@/assets/icons/Arrows/PNG/AOG_arrow_left_wh.webp"
        alt="arrow-to-left"
      />
    </div>
    <ul class="navbar-nav">
      <li class="nav-item">
        <router-link to="/tabs/Calculation" class="nav-link">
          <img
            class="nav-svg"
            src="../assets/icons/PNG/WindanalyseTool_icons_Zeichenfläche1Kopie19.webp"
            alt="calculation-icon-white"
          />
          <img
            class="nav-svg-green"
            src="../assets/icons/PNG/WindanalyseTool_icons_Zeichenfläche1Kopie18.webp"
            alt="calculation-icon-green"
          />
          <span class="link-text">Calculation</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="this.$store.getters.showSideBarElements">
        <router-link to="/tabs/Dashboard" class="nav-link">
          <img
            class="nav-svg"
            src="../assets/icons/PNG/AOG_Windanalyse-Tool_dashboard_wh.webp"
            alt="Dashboard-icon-white"
          />
          <img
            class="nav-svg-green"
            src="../assets/icons/PNG/AOG_Windanalyse-Tool_dashboard_gr.webp"
            alt="Dashboard-icon-green"
          />
          <span class="link-text">Dashboard</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="this.$store.getters.showSideBarElements">
        <router-link to="/tabs/OutputPrediction" class="nav-link">
          <img
            class="nav-svg"
            src="../assets/icons/PNG/outPutPrediction_icon_white.webp"
            alt="Output-prediction-white-notOwned"
          />
          <img
            class="nav-svg-green"
            src="../assets/icons/PNG/outPutPrediction_icon_green.webp"
            alt="Output-prediction-green-notOwned"
          />
          <span class="link-text">Output prediction</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="this.$store.getters.showSideBarElements">
        <router-link to="/tabs/WindspeedCalculation" class="nav-link">
          <img
            class="nav-svg"
            src="../assets/icons/PNG/AOG_Windanalyse-Tool_Windspeed_wh.webp"
            alt="Windspeed-calculation-white"
          />
          <img
            class="nav-svg-green"
            src="../assets/icons/PNG/AOG_Windanalyse-Tool_Windspeed_gr.webp"
            alt="Windspeed-calculation-green"
          />
          <span class="link-text">Windspeed calculation</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="this.$store.getters.showSideBarElements">
        <router-link to="/tabs/ProfitabilityAnalysis" class="nav-link">
          <img
            class="nav-svg"
            src="../assets/icons/PNG/AOG_Windanalyse-Tool_profitability_wh.webp"
            alt="Profitability-analysis-white"
          />
          <img
            class="nav-svg-green"
            src="../assets/icons/PNG/AOG_Windanalyse-Tool_profitability_gr.webp"
            alt="Profitability-analysis-green"
          />
          <span class="link-text">Profitability analysis</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="this.$store.getters.showSideBarElements">
        <router-link to="/tabs/AirDensityCalculation" class="nav-link">
          <img
            class="nav-svg"
            src="../assets/icons/PNG/AOG_Windanalyse-Tool_density_wh.webp"
            alt="Air-density-calculation-white"
          />
          <img
            class="nav-svg-green"
            src="../assets/icons/PNG/AOG_Windanalyse-Tool_density_gr.webp"
            alt="Air-density-calculation-green"
          />
          <span class="link-text">Air density calculation</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="this.$store.getters.showSideBarElements">
        <router-link to="/tabs/WeibullDistribution" class="nav-link">
          <img
            class="nav-svg"
            src="../assets/icons/PNG/AOG_Windanalyse-Tool_weibull_wh.webp"
            alt="Weibull-distribution-white"
          />
          <img
            class="nav-svg-green"
            src="../assets/icons/PNG/AOG_Windanalyse-Tool_weibull_gr.webp"
            alt="Weibull-distribution-green"
          />
          <span class="link-text">Weibull distribution</span>
        </router-link>
      </li>

      <li class="nav-item" v-if="this.$store.getters.showSideBarElements">
        <router-link to="/createReport" class="nav-link">
          <img
            class="nav-svg"
            src="../assets/icons/PNG/WindanalyseTool_icons_Zeichenfläche1Kopie21.webp"
            alt="Create-report-white"
          />
          <img
            class="nav-svg-green"
            src="../assets/icons/PNG/WindanalyseTool_icons_Zeichenfläche1Kopie20.webp"
            alt="Create-report-green"
          />
          <span class="link-text">Create report</span>
        </router-link>
      </li>
    </ul>
  </div>
  <header class="bg">
    <div v-for="i in 15" :key="i" :class="`dotWrapper dotWrapper-${i}`">
      <div :class="`dot dot-${i}`"></div>
    </div>
    <div class="header-section">
      <h1 class="header">
        {{
          $router.currentRoute._rawValue.path
            .replace("/tabs/", "")
            .replace(/([A-Z])/g, " $1")
            .trim()
        }}
      </h1>

      <div class="dropdown">
        <p class="dropdown-title">Saved calculations</p>
        <div class="dropdown-content">
          <div
            v-for="listElement in this.$store.getters.calculationList"
            :key="listElement.id"
            @click="loadCalculationFromList(listElement.id)"
          >
            <div>{{ listElement.name }}</div>

            <img
              @click.stop.prevent="editCalculaltion(listElement.id)"
              class="dropdown-content-editItem-icon"
              src="../assets/icons/SVG/externeIcons/edit-box.svg"
            />
          </div>
          <p
            v-if="this.$store.getters.calculation.dashboard"
            @click="saveCurrentCalculation()"
            class="saveCalculationButton"
          >
            Save current calculation
          </p>
        </div>
        <img
          class="dropdown-icon-down"
          src="../assets/icons/Arrows/PNG/AOG_arrow_down_wh.webp"
          alt="home-icon"
        />
        <img
          class="dropdown-icon-up"
          src="../assets/icons/Arrows/PNG/AOG_arrow_up_wh.webp"
          alt="home-icon"
        />
      </div>

      <router-link to="/tabs/Profile" class="profileLink">
        <div class="profileButton">
          <p class="profileName">{{ this.$store.getters.nameOfUser }}</p>
          <p class="companyName">[ {{ this.$store.getters.businessName }} ]</p>
        </div>
      </router-link>
    </div>
  </header>
  <main>
    <router-view />
  </main>
</template>

<script>
import { useStore } from "vuex";
const url = process.env.VUE_APP_ENV_SERVER || "";
import axios from "axios";
import firebase from "firebase";
import Swal from "sweetalert2";
import jwt_decode from "jwt-decode";
import router from "@/router";
import { computed } from "vue";
export default {
  name: "OutputPrediction",
  setup() {
    const store = useStore();
    const calculationList = computed(() => store.getters.calculationList);
    async function loadCalculationFromList(id) {
      store.dispatch("setShowLoadingScreen", true);
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          axios
            .post(url + "/loadCalculationFromList", {
              id: id,
              idToken: idToken,
            })
            .then((response) => response.data)
            .then((serverData) => {
              const decoded = jwt_decode(serverData.token);
              store.dispatch("setCalculation", decoded.calculation);
              store.dispatch("setToken", serverData.token);
              store.dispatch("showSideBarElements");
              router.push("/tabs/Dashboard");
              store.dispatch("setShowLoadingScreen", false);
              console.log(decoded.calculation);
            });
        });
    }
    function checkSpecialChar(name) {
      const re = /^([\w.-][\s]*)+$/;
      return !re.test(name);
    }
    async function saveCurrentCalculation() {
      const { value: name } = await Swal.fire({
        title: "Enter name for calculation",
        input: "text",
        inputLabel: "Name",
        showCancelButton: true,
        inputAttributes: {
          maxlength: 40,
        },
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
          if (checkSpecialChar(value)) {
            return "Please do not use special characters";
          }
          if (calculationList.value.length > 4) {
            return "You can only save up to 5 calculations";
          }
        },
      });
      if (name) {
        sendSavedCalculationToServer(name.trim());
      }
    }
    function sendSavedCalculationToServer(name) {
      store.dispatch("setShowLoadingScreen", true);
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          axios
            .post(url + "/saveCalculation", {
              token: store.getters.token,
              idToken: idToken,
              name: name,
            })
            .then(() => {
              store.dispatch("getSavedCalculation");
              store.dispatch("setShowLoadingScreen", false);
            });
        });
    }
    function deleteCalculation(id) {
      store.dispatch("setShowLoadingScreen", true);
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          axios
            .post(url + "/deleteCalculation", {
              idToken: idToken,
              id: id,
            })
            .then(() => {
              store.dispatch("getSavedCalculation");
              store.dispatch("setShowLoadingScreen", false);
            });
        });
    }
    function sendUpdatedCalculationToServer(name, id) {
      store.dispatch("setShowLoadingScreen", true);
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then(function (idToken) {
          axios
            .post(url + "/editSavedCalculation", {
              token: store.getters.token,
              idToken: idToken,
              name: name,
              id: id,
            })
            .then(() => {
              store.dispatch("getSavedCalculation");
              store.dispatch("setShowLoadingScreen", false);
            });
        });
    }
    function editCalculaltion(id) {
      Swal.fire({
        title: "Edit Calculation",
        showDenyButton: true,
        showCancelButton: true,
        input: "text",
        inputLabel: "Name",
        confirmButtonText: "Change name",
        denyButtonText: "Delete calculation",
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
          if (checkSpecialChar(value)) {
            return "Please do not use special characters";
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          sendUpdatedCalculationToServer(result.value, id);
        } else if (result.isDenied) {
          deleteCalculation(id);
        }
      });
      console.log(id);
    }
    return {
      saveCurrentCalculation,
      loadCalculationFromList,
      deleteCalculation,
      editCalculaltion,
    };
  },
};
</script>
